<template>
  <div>
    <app-layout>
      <template v-slot:header>
        <Header :title="$t('building_cleaning_list')"
                :is-filter="false"
        >
        </Header>
      </template>
      <template v-slot:header-mobile>
        <HeaderMobile :title="$t('building_cleaning_list')"
                      :is-filter="false"

        >
        </HeaderMobile>
      </template>
      <vue-cal
          :events="events"
          active-view="month"
          :disable-views="['years','year','week','day']"
          :time-step="60"
          events-on-month-view
          @view-change="onViewChange"
          class="custom-vuecal"
          :cell-height="cellHeight"
      >
        <template #event="{ event }">
          <div
              class="vuecal__event-title border"
              :style="{ backgroundColor: event.color, color: '#fff' }"
              v-html="event.title"
          ></div>

        </template>
      </vue-cal>
    </app-layout>
  </div>
</template>
<script>
// Template
import AppLayout from '@/layouts/AppLayout'
import Header from '@/layouts/AppLayout/Header'
import HeaderMobile from '@/layouts/AppLayout/HeaderMobile'
// Components
import VueCal from 'vue-cal'
import 'vue-cal/dist/vuecal.css'
import '/src/locales/en/vuecal.js'
import HousingBuildingDaysServices from "@/services/HousingBuildingDaysServices";

export default {
  components: {
    AppLayout,
    Header,
    VueCal,
    HeaderMobile,
  },
  metaInfo() {
    return {
      title: this.$t('airport_transfers_list')
    }
  },
  data() {
    return {
      eventId: null,
      events: [],
      cellHeight: 500,
      loading: false,
      startDate: null,
      currentMonth: null,
      currentYear: null
    }
  },
  computed: {},
  mounted() {
    this.dateFormat()
  },
  methods: {
    dateFormat() {
      const currentDate = new Date();
      const year = currentDate.getFullYear();
      const month = String(currentDate.getMonth() + 1).padStart(2, '0');
      this.getRows(month, year)
    },
    getRandomColor() {
      const letters = '0123456789ABCDEF';
      let color = '#';
      for (let i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
      }
      return color
    },
    onViewChange({startDate, view}) {
      if (view !== 'week') {
        this.setCurrentMonthAndYear(startDate);
      }
    },
    setCurrentMonthAndYear(date) {
      this.currentMonth = date?.getMonth() + 1;
      this.currentYear = date?.getFullYear();
      this.getRows(this.currentMonth, this.currentYear)
    },
    getRows(month, year) {
      return HousingBuildingDaysServices.getAll(month, year)
          .then((response) => {
            const events = []
            const data = response.data.data;
            Object.entries(data).forEach(([key, value]) => {
              const color = this.getRandomColor()
              for (let i = 0; i < value.length; i++) {
                events.push(
                    {
                      title: 'Building' + ' ' + key,
                      name: key,
                      start: value[i],
                      end: value[i],
                      color: color
                    }
                )
              }
            })
            this.events = events
          }).catch(error => {
            this.showErrors(error)
          })
    },


  }
}
</script>
<style>
.vuecal__cell {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: 100px;
  height: auto;
  transition: 0.15s ease-in-out background-color;
}

</style>

