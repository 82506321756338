import Api from '@/services/Index';

const getAll = async (month,year) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get(`/housing/buildings/days/${month}/${year}`);
}

export default {
    getAll,
}
